export function initializeGTM() {
  // Get stored consent
  const storedConsent = localStorage.getItem('userConsent');
  const initialConsent = storedConsent ? JSON.parse(storedConsent) : null;

  // If we have stored consent, update it using official API
  if (initialConsent) {
    window.gtag('consent', 'update', initialConsent);
  }

  // Initialize GTM
  (function(w,d,s,l,i){
    w[l]=w[l]||[];
    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-N9C4C7MB');
} 