import { useState, useEffect } from 'react';
import { LanguageSwitcher } from '@/components/LanguageSwitcher';
import { Logo } from '@/components/Logo';
import { ServiceCard } from '@/components/ServiceCard';
import { PortfolioCard } from '@/components/PortfolioCard';
import { AnimatedSection } from '@/components/AnimatedSection';
import { translations } from '@/lib/translations';
import { Mail, MessageCircle } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { ContactButton } from './components/ContactButton';
import { GoogleMap } from './components/GoogleMap';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { ContactForm } from '@/components/ContactForm';
import { ContactDialog } from '@/components/ContactDialog';
import { TrackedPhoneNumber } from '@/components/TrackedPhoneNumber';
import { lazy, Suspense } from 'react';
// Lazy load non-critical components
const Slider = lazy(() => import('react-slick'));
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { getImageUrl, getResponsiveImage, imageDimensions } from '@/lib/image-utils';

// Declare dataLayer and gtag for TypeScript
declare global {
  interface Window {
    dataLayer: Array<{
      [key: string]: any;
      event?: string;
      conversion_type_variable?: string;
      consent?: {
        ad_storage: "granted" | "denied";
        analytics_storage: "granted" | "denied";
        ad_personalization: "granted" | "denied";
        ad_user_data: "granted" | "denied";
      };
    }>;
    gtag: (...args: any[]) => void;
  }
}

// Add prop for default language
interface AppProps {
  defaultLang: 'de' | 'en';
}

// Update the consent type definitions to fix type errors
interface ConsentData {
  'ad_storage': 'granted' | 'denied';
  'analytics_storage': 'granted' | 'denied';
  'ad_personalization': 'granted' | 'denied';
  'ad_user_data': 'granted' | 'denied';
}

export default function App({ defaultLang }: AppProps) {
  const [currentLang] = useState<'de' | 'en'>(defaultLang);
  const t = translations[currentLang];
  const [imageLoaded, setImageLoaded] = useState(false);

  // Move settings here to access t
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    pauseOnHover: true,
    cssEase: "cubic-bezier(0.645, 0.045, 0.355, 1)",
    lazyLoad: 'progressive' as const,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false
        }
      }
    ],
    initialSlide: Math.floor(Math.random() * t.hero.slides.length),
    beforeChange: (current: number, next: number) => {
      // Current slide continues zooming
      const currentSlide = document.querySelector(
        `.slick-slide[data-index="${current}"] img`
      );
      if (currentSlide) {
        currentSlide.classList.remove('zoom-active');
        currentSlide.classList.add('zoom-next');
      }

      // Next slide starts from active state
      const nextSlide = document.querySelector(
        `.slick-slide[data-index="${next}"] img`
      );
      if (nextSlide) {
        nextSlide.classList.add('zoom-active');
      }
    },
    afterChange: (current: number) => {
      // Clean up after transition
      document.querySelectorAll('.hero-carousel .slick-slide img').forEach(img => {
        img.classList.remove('zoom-next');
      });
    },
    onInit: () => {
      // Simplify initial load
      const firstSlide = document.querySelector('.hero-carousel .slick-current img');
      if (firstSlide) {
        firstSlide.classList.add('zoom-active');
      }
    },
    onReInit: () => {
      const currentSlide = document.querySelector('.hero-carousel .slick-current img');
      if (currentSlide) {
        currentSlide.classList.add('zoom-active');
      }
    }
  };

  const portfolioItems = t.portfolio.items.map(item => {
    if (item.type === 'before-after') {
      return {
        ...item,
        type: 'before-after' as const,
        keywords: ['antique restoration', 'furniture repair', 'upholstery'] as string[]
      };
    }
    return {
      ...item,
      type: 'showcase' as const,
      keywords: ['antique restoration', 'furniture repair', 'upholstery'] as string[]
    };
  });

  const handleCallClick = (e: React.MouseEvent) => {
    e.preventDefault();
    // Remove or update any AdWords-specific dataLayer pushes
    window.dataLayer?.push({
      'event': 'conversion',
      'conversion_type_variable': 'phone_call'
    });
    window.location.href = 'tel:+41797389751';
  };

  const handleWhatsAppClick = (e: React.MouseEvent) => {
    e.preventDefault();
    window.dataLayer?.push({
      'event': 'conversion',
      'conversion_type_variable': 'whatsapp_click'
    });
    window.location.href = 'https://wa.me/41797389751?text=...';
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: '-80px 0px 0px 0px', // Account for header height
  });

  // Contact button component to avoid duplication
  const ContactButtons = ({ className = '', orientation = 'horizontal', currentLang }: { className?: string; orientation?: 'horizontal' | 'vertical'; currentLang: 'de' | 'en'; }) => (
    <div className={`flex ${orientation === 'vertical' ? 'flex-col md:flex-row' : 'flex-row'} gap-2 ${className}`}>
      <ContactDialog currentLang={currentLang}>
        <button
          className="flex-1 md:flex-initial inline-flex items-center justify-center gap-2 bg-[#334B40] hover:bg-[#3D5A4C] text-white px-4 py-2 rounded-md transition-all duration-200 hover:scale-105 text-sm whitespace-nowrap"
        >
          <Mail className="h-4 w-4" />
          <span className="font-medium">{currentLang === 'de' ? 'Kontakt' : 'Contact'}</span>
        </button>
      </ContactDialog>

      <ContactButton 
        type="whatsapp"
        className="flex-1 md:flex-initial inline-flex items-center justify-center gap-2 bg-gray-100/95 hover:bg-white text-[#334B40] px-4 py-2 rounded-md transition-all duration-200 hover:scale-105 text-sm whitespace-nowrap"
        currentLang={currentLang}
      >
        <MessageCircle className="h-4 w-4 text-[#25D366]" />
        <span className="font-medium">{t.buttons.whatsapp}</span>
      </ContactButton>
    </div>
  );

  // Update language switcher to use navigation
  const handleLanguageChange = (newLang: 'de' | 'en') => {
    const currentPath = window.location.pathname;
    const newPath = currentPath.replace(/\/(de|en)\//, `/${newLang}/`);
    window.location.href = newPath;
  };

  // Keep these handlers as they're used in CookieConsent component
  const handleAcceptCookies = () => {
    try {
      window.dataLayer = window.dataLayer || [];
      const consentData: ConsentData = {
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
        'ad_personalization': 'granted',
        'ad_user_data': 'granted'
      };

      // 1. Push to dataLayer
      window.dataLayer.push({
        'event': 'user_consent_granted',
        'consent': consentData
      });

      // 2. Store in localStorage
      localStorage.setItem('userConsent', JSON.stringify(consentData));

      // 3. Immediately update consent state - both ways to ensure it works
      if (window.gtag) {
        window.gtag('consent', 'update', consentData);
        // Also try direct update
        window.dataLayer.push({
          'event': 'consent.update',
          ...consentData
        });
      }
    } catch (error) {
      console.error('Error handling cookie consent:', error);
    }
  };

  const handleDeclineCookies = () => {
    try {
      window.dataLayer = window.dataLayer || [];
      const consentData: ConsentData = {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'ad_personalization': 'denied',
        'ad_user_data': 'denied'
      };

      // 1. Push to dataLayer
      window.dataLayer.push({
        'event': 'user_consent_denied',
        'consent': consentData
      });

      // 2. Store in localStorage
      localStorage.setItem('userConsent', JSON.stringify(consentData));

      // 3. Immediately update consent state
      window.gtag('consent', 'update', consentData);
    } catch (error) {
      console.error('Error handling cookie consent:', error);
    }
  };

  // Add state for mobile menu if you need it
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Add toggle handler
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prev => !prev);
  };

  // Remove console.log statements that were present in production code
  useEffect(() => {
    // Environment check without console logging
    if (process.env.NODE_ENV === 'development') {
      // Only log in development
      console.log('Environment:', {
        NODE_ENV: process.env.NODE_ENV,
        VITE_APP_URL: import.meta.env.VITE_APP_URL,
        hasGoogleMapsKey: !!import.meta.env.VITE_GOOGLE_MAPS_API_KEY
      });
    }
  }, []);

  useEffect(() => {
    // Health check - no console logs in production
    fetch('/health.txt')
      .then(() => {
        // Success, but don't log in production
        if (process.env.NODE_ENV === 'development') {
          console.log('Health check succeeded');
        }
      })
      .catch(error => {
        // Only log errors in any environment
        console.error('Health check failed:', error);
      });
  }, []);

  return (
    <>
      <style>
        {`
          .hero-carousel .slick-slide > div {
            height: 100vh;
          }
          /* Base state with slower movement */
          .hero-carousel .slick-slide img {
            transform: scale(1.05) translate(-4%, -1%);
            transition: transform 15s linear;
            will-change: transform;
          }
          /* Active slide with slower speeds */
          .hero-carousel .slick-slide img.zoom-active {
            transform: scale(1.3) translate(4%, 1%);
            transition: 
              transform 15s linear,
              scale 10s linear;
          }
          /* Next slide continues the slower movement */
          .hero-carousel .slick-slide img.zoom-next {
            transform: scale(1.5) translate(8%, 2%);
            transition: 
              transform 15s linear,
              scale 10s linear;
          }
        `}
      </style>

      <Helmet>
        <title>Polsterei am HB Zürich | Atelier Grünenwald</title>
        
        {/* Language meta tags */}
        <html lang={currentLang} />
        <link rel="alternate" hrefLang="de" href={`https://polsterei-hb-zuerich.ch/de${window.location.pathname.replace(/^\/(de|en)/, '')}`} />
        <link rel="alternate" hrefLang="en" href={`https://polsterei-hb-zuerich.ch/en${window.location.pathname.replace(/^\/(de|en)/, '')}`} />
        <link rel="alternate" hrefLang="x-default" href="https://polsterei-hb-zuerich.ch/de/" />
        
        {/* Add robots meta tag */}
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        
        {/* Remove any existing canonical links and set the new one */}
        {currentLang === 'de' ? (
          <link rel="canonical" href={`https://polsterei-hb-zuerich.ch/de${window.location.pathname.replace(/^\/(de|en)/, '')}`} />
        ) : (
          <link rel="canonical" href={`https://polsterei-hb-zuerich.ch/en${window.location.pathname.replace(/^\/(de|en)/, '')}`} />
        )}
        
        <meta name="description" content={currentLang === 'de' ? 
          "Professionelle Polsterei in Zürich. Traditionelle Polsterarbeiten, Möbelrestaurierung und Neubezüge. 2 Minuten vom Hauptbahnhof Zürich." : 
          "Professional upholstery in Zurich. Traditional upholstery work, furniture restoration and reupholstery. Just 2 minutes from Zurich main station."
        } />
        <meta name="keywords" content={currentLang === 'de' ? 
          "polsterei zürich, polsterer zürich, möbelpolsterei, polsterarbeiten, möbelrestaurierung, hb zürich" : 
          "upholstery zurich, upholsterer zurich, furniture upholstery, upholstery work, furniture restoration, zurich hb"
        } />
        
        {/* Update og:url and other OG tags to be dynamic */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Polsterei am HB Zürich | Atelier Grünenwald" />
        <meta property="og:description" content={currentLang === 'de' ? 
          "Professionelle Polsterei in Zürich. Traditionelle Polsterarbeiten, Möbelrestaurierung. 2 Minuten vom Hauptbahnhof." : 
          "Professional upholstery in Zurich. Traditional upholstery, furniture restoration. 2 minutes from main station."
        } />
        <meta property="og:image" content="https://polsterei-hb-zuerich.ch/images/og-image.webp" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={currentLang === 'de' ? 
          `https://polsterei-hb-zuerich.ch/de${window.location.pathname.replace(/^\/(de|en)/, '')}` : 
          `https://polsterei-hb-zuerich.ch/en${window.location.pathname.replace(/^\/(de|en)/, '')}`
        } />
        <meta property="og:locale" content={currentLang === 'de' ? 'de_CH' : 'en_GB'} />
        
        {/* Add Twitter card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Polsterei am HB Zürich | Atelier Grünenwald" />
        <meta name="twitter:description" content={currentLang === 'de' ? 
          "Professionelle Polsterei in Zürich. Traditionelle Polsterarbeiten, Möbelrestaurierung. 2 Minuten vom Hauptbahnhof." : 
          "Professional upholstery in Zurich. Traditional upholstery, furniture restoration. 2 minutes from main station."
        } />
        <meta name="twitter:image" content="https://polsterei-hb-zuerich.ch/images/og-image.webp" />
        
        {/* Add PWA-related meta tags */}
        <meta name="theme-color" content="#334B40" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Polsterei HB Zürich" />
        <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": currentLang === 'de' ? "Atelier Grünenwald" : "Atelier Gruenenwald",
            "image": [
              "https://polsterei-hb-zuerich.ch/atelier-gruenenwald-logo.svg",
              "https://polsterei-hb-zuerich.ch/images/hero/upholstery-workshop-zurich-large.webp"
            ],
            "description": currentLang === 'de' 
              ? "Professionelle Polsterei in Zürich. Traditionelle Polsterarbeiten, Möbelrestaurierung und Neubezüge."
              : "Professional upholstery in Zurich. Traditional upholstery work, furniture restoration and reupholstery.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Tellstrasse 38",
              "addressLocality": "Zürich",
              "postalCode": "8004",
              "addressCountry": "CH"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 47.378337,
              "longitude": 8.533440
            },
            "@id": `https://polsterei-hb-zuerich.ch/${currentLang}`,
            "url": `https://polsterei-hb-zuerich.ch/${currentLang}`,
            "telephone": "+41442428980",
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "http://schema.org/Monday",
                "http://schema.org/Tuesday",
                "http://schema.org/Wednesday",
                "http://schema.org/Thursday",
                "http://schema.org/Friday"
              ],
              "opens": "09:00",
              "closes": "18:00"
            },
            "priceRange": "$$",
            "inLanguage": currentLang === 'de' ? "de-CH" : "en",
            "hasMap": "https://www.google.com/maps/place/Tellstrasse+38,+8004+Z%C3%BCrich",
            "sameAs": [
              "https://wa.me/41797389751",
              "https://www.instagram.com/AtelierGruenenwald"
            ]
          })}
        </script>
        
        {/* Preload critical hero image with explicit dimensions */}
        <link 
          rel="preload" 
          as="image"
          type="image/webp"
          href={`/images/hero/${t.hero.slides[0].imageId}-large.webp`}
          media="(min-width: 1280px)"
          imageSizes="100vw"
          fetchpriority="high"
        />
        <link 
          rel="preload" 
          as="image"
          type="image/webp"
          href={`/images/hero/${t.hero.slides[0].imageId}-medium.webp`}
          media="(min-width: 768px) and (max-width: 1279px)"
          imageSizes="100vw"
          fetchpriority="high"
        />
        <link 
          rel="preload" 
          as="image"
          type="image/webp"
          href={`/images/hero/${t.hero.slides[0].imageId}-small.webp`}
          media="(max-width: 767px)"
          imageSizes="100vw"
          fetchpriority="high"
        />
        
        {/* Optimize font loading with fallback */}
        <link 
          rel="preload" 
          href="/fonts/Inter-Regular.woff2" 
          as="font" 
          type="font/woff2" 
          crossorigin="anonymous"
          fetchpriority="high"
        />
        
        {/* Add font-display swap with fallback */}
        <style type="text/css">{`
          @font-face {
            font-family: 'Inter';
            src: url('/fonts/Inter-Regular.woff2') format('woff2');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
          }
          
          @font-face {
            font-family: 'Inter';
            src: url('/fonts/Inter-Bold.woff2') format('woff2');
            font-weight: 700;
            font-style: normal;
            font-display: swap;
          }
        `}</style>
      </Helmet>
      
      <div className="min-h-screen bg-[#f8f8f8] text-[#2B1810]">
        {/* Header */}
        <header className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-sm border-b">
          {/* Main header content */}
          <div className="container mx-auto px-4 h-20 flex items-center justify-between">
            {/* Logo und Language Switcher */}
            <div className="flex items-center space-x-4">
              <div className="w-12 h-12">
                <Logo />
              </div>
              <LanguageSwitcher currentLang={currentLang} onLanguageChange={handleLanguageChange} />
            </div>
            
            {/* Navigation and Menu Button */}
            <div className="flex items-center gap-4">
              {/* Navigation */}
              <nav className="hidden md:flex items-center space-x-8 text-sm font-medium" aria-label="Main navigation">
                <a href="#services" className="text-[#2B1810] hover:text-[#334B40] transition">{t.nav.services}</a>
                <a href="#portfolio" className="text-[#2B1810] hover:text-[#334B40] transition">{t.nav.portfolio}</a>
                <a href="#contact" className="text-[#2B1810] hover:text-[#334B40] transition">{t.nav.contact}</a>
              </nav>
              
              {/* Desktop CTA Buttons - always visible on desktop */}
              <div className="hidden md:flex flex-row items-center gap-2">
                <ContactButtons orientation="horizontal" currentLang={currentLang} />
              </div>
              
              {/* Mobile menu button */}
              <button 
                onClick={toggleMobileMenu}
                className="md:hidden p-2 text-[#2B1810]"
                aria-label="Toggle menu"
                aria-expanded={isMobileMenuOpen}
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMobileMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
                </svg>
              </button>
            </div>
          </div>
        </header>

        {/* Mobile menu */}
        <div 
          className={`
            md:hidden fixed inset-x-0 top-20 
            bg-white/95 backdrop-blur-sm 
            transform transition-transform duration-300 ease-in-out 
            z-40 border-b
            ${isMobileMenuOpen ? 'translate-y-0' : '-translate-y-full'}
          `}
        >
          <nav className="container mx-auto px-4 py-6 flex flex-col space-y-6 text-base font-medium">
            <a href="#services" className="text-[#2B1810] hover:text-[#334B40] transition" onClick={toggleMobileMenu}>{t.nav.services}</a>
            <a href="#portfolio" className="text-[#2B1810] hover:text-[#334B40] transition" onClick={toggleMobileMenu}>{t.nav.portfolio}</a>
            <a href="#contact" className="text-[#2B1810] hover:text-[#334B40] transition" onClick={toggleMobileMenu}>{t.nav.contact}</a>
          </nav>
        </div>

        {/* Hero Section */}
        <section className="relative h-screen">
          {/* Carousel container */}
          <div className="absolute inset-0">
            <Suspense fallback={
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <span className="sr-only">Loading...</span>
                <div className="animate-pulse w-16 h-16 rounded-full bg-gray-300"></div>
              </div>
            }>
              <Slider {...settings} className="hero-carousel h-full">
                {t.hero.slides.map((slide, index) => (
                  <div key={index} className="relative h-full overflow-hidden">
                    <picture>
                      <source
                        media="(min-width: 1280px)"
                        srcSet={`/images/hero/${slide.imageId}-large.webp`}
                        type="image/webp"
                        width={imageDimensions.hero.large.width}
                        height={imageDimensions.hero.large.height}
                      />
                      <source
                        media="(min-width: 768px)"
                        srcSet={`/images/hero/${slide.imageId}-medium.webp`}
                        type="image/webp"
                        width={imageDimensions.hero.medium.width}
                        height={imageDimensions.hero.medium.height}
                      />
                      <img
                        src={`/images/hero/${slide.imageId}-small.webp`}
                        alt={slide.alt}
                        width={imageDimensions.hero.small.width}
                        height={imageDimensions.hero.small.height}
                        className={`w-full h-full object-cover object-center ${index === 0 ? 'zoom-active' : ''}`}
                        loading={index === 0 ? "eager" : "lazy"}
                        fetchpriority={index === 0 ? "high" : "auto"}
                        onLoad={() => index === 0 && setImageLoaded(true)}
                      />
                    </picture>
                    <div className="absolute inset-0 bg-black/40" />
                  </div>
                ))}
              </Slider>
            </Suspense>
          </div>

          {/* Hero Content Overlay */}
          <div className="absolute inset-0 flex flex-col justify-between py-8 md:py-12">
            <div className="flex-1 flex items-center justify-center">
              <AnimatedSection className="text-center w-full px-4">
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="flex flex-col items-center"
                >
                  <h1 className="text-2xl md:text-4xl font-light tracking-wider mb-2 text-white whitespace-normal">
                    {t.hero.title}
                  </h1>
                  <div className="relative mt-2">
                    <span className="absolute -left-8 top-1/2 -translate-y-1/2 text-sm md:text-base font-light tracking-wider opacity-80 text-white hidden md:block">
                      by
                    </span>
                    <h2 className="text-3xl md:text-6xl font-light tracking-wider text-white whitespace-normal px-4 md:px-0">
                      {t.hero.subtitle}
                    </h2>
                  </div>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ 
                    duration: 0.5,
                    delay: 0.5
                  }}
                  className="mt-12"
                >
                  <ContactButtons className="justify-center" orientation="vertical" currentLang={currentLang} />
                </motion.div>
              </AnimatedSection>
            </div>

            {/* Tagline at bottom - adjusted for mobile */}
            <div className="w-full overflow-hidden px-4 md:px-0">
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
                className="container mx-auto pb-4 md:pb-8"
              >
                <h2 className="font-light tracking-wider text-[#B8A164] text-center
                  text-sm sm:text-base md:text-xl lg:text-2xl xl:text-3xl"
                >
                  <span className="block md:inline">{t.hero.tagline.line1}</span>
                  <span className="block mt-1 md:mt-0 md:inline">
                    <span className="hidden md:inline mx-2">-</span>
                    {t.hero.tagline.line2}
                  </span>
                </h2>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Services Section */}
        <section 
          id="services" 
          className="py-24 relative" 
          aria-labelledby="services-title"
          style={{
            backgroundImage: `url("${getImageUrl('/background.webp')}")`,
            backgroundRepeat: 'repeat',
            backgroundSize: '300px',
            backgroundPosition: 'center',
          }}
        >
          {/* Light overlay - changed opacity to match portfolio */}
          <div className="absolute inset-0 bg-[#f8f8f8]/75" />
          <div className="container mx-auto px-4 relative">
            <AnimatedSection className="text-center mb-16">
              <h2 id="services-title" className="text-4xl md:text-5xl font-light tracking-wide">
                {t.services.title}
              </h2>
            </AnimatedSection>
            
            <div className="space-y-12">
              {t.services.items.map((service, index) => (
                <ServiceCard
                  key={service.title}
                  title={service.title}
                  description={service.description}
                  image={service.image}
                  index={index}
                  reverse={index % 2 !== 0}
                />
              ))}
            </div>
          </div>
        </section>

        {/* Portfolio Section */}
        <section 
          id="portfolio" 
          className="py-24 relative" 
          style={{
            backgroundImage: `url("${getImageUrl('/background.webp')}")`,
            backgroundRepeat: 'repeat',
            backgroundSize: '300px',
          }}
        >
          {/* Darker overlay */}
          <div className="absolute inset-0 bg-[#f8f8f8]/75" />
          <div className="container mx-auto px-4 relative">
            <AnimatedSection className="text-center mb-16">
              <h2 className="text-3xl font-light tracking-wide">
                PORTFOLIO
              </h2>
            </AnimatedSection>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {portfolioItems.map((item, index) => (
                <PortfolioCard
                  key={item.title}
                  {...item}
                  index={index}
                  className="h-full"
                />
              ))}
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section 
          id="contact" 
          className="py-24 relative"
          style={{
            backgroundImage: `url("${getImageUrl('/background.webp')}")`,
            backgroundRepeat: 'repeat',
            backgroundSize: '300px',
          }}
        >
          {/* Light overlay */}
          <div className="absolute inset-0 bg-[#f8f8f8]/90" />
          <div className="container mx-auto px-4 relative">
            {/* Title and Description */}
            <div className="mb-12">
              <div className="text-center" ref={ref}>
                <h2 className="text-3xl font-light tracking-wide mb-4">
                  {t.contact.subtitle}
                </h2>
                <p className="text-neutral-600">{t.contact.description}</p>
              </div>
            </div>

            {/* Contact Information and Form */}
            <div className="grid grid-cols-1 md:grid-cols-[auto,1fr] gap-8 md:gap-12 mb-12 items-baseline">
              {/* Left Column - Contact Info */}
              <div className="space-y-6 md:space-y-8 md:min-w-[280px] order-2 md:order-1">
                <div>
                  <h2 className="text-xl font-medium mb-4">{currentLang === 'de' ? 'Adresse' : 'Address'}</h2>
                  <div className="space-y-2">
                    <p>Tellstrasse 38</p>
                    <p>8004 Zürich</p>
                  </div>
                </div>

                <div>
                  <h2 className="text-xl font-medium mb-4">{currentLang === 'de' ? 'Öffnungszeiten' : 'Opening Hours'}</h2>
                  <div className="space-y-2">
                    <p>{currentLang === 'de' ? 'Mo-Fr: 09:00 - 18:00' : 'Mon-Fri: 09:00 - 18:00'}</p>
                    <p>{currentLang === 'de' ? 'Sa: Nach Vereinbarung' : 'Sat: By appointment'}</p>
                  </div>
                </div>

                <div>
                  <h2 className="text-xl font-medium mb-4">{currentLang === 'de' ? 'Kontakt' : 'Contact'}</h2>
                  <div className="space-y-2">
                    <p>info@ateliergruenenwald.ch</p>
                    <div className="flex">
                      <span className="w-16">Tel:</span>
                      <TrackedPhoneNumber number="+41442428980" className="hover:text-[#334B40] transition-colors">
                        +41 44 242 89 80
                      </TrackedPhoneNumber>
                    </div>
                    <div className="flex">
                      <span className="w-16">Mobile:</span>
                      <TrackedPhoneNumber number="+41797389751" className="hover:text-[#334B40] transition-colors">
                        +41 79 738 97 51
                      </TrackedPhoneNumber>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Column - Contact Form */}
              <div className="w-full order-1 md:order-2">
                <ContactForm currentLang={currentLang} />
              </div>
            </div>

            {/* Map Section */}
            <AnimatedSection className="container mx-auto px-4">
              <div className="relative w-full h-[400px] rounded-lg overflow-hidden mx-auto max-w-[1920px]">
                <ErrorBoundary fallback={<div className="w-full h-full flex items-center justify-center bg-gray-100">Error loading map</div>}>
                  {import.meta.env.VITE_GOOGLE_MAPS_API_KEY && (
                    <GoogleMap 
                      apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
                      placeId="ChIJb9WK7SALBQMRecnC-8QFKF4"
                      language={currentLang}
                    />
                  )}
                </ErrorBoundary>
              </div>
            </AnimatedSection>
          </div>
        </section>

        {/* Footer */}
        <footer className="py-8 bg-white border-t">
          <div className="container mx-auto px-4 text-center text-sm text-neutral-600">
            <div className="flex flex-col md:flex-row items-center justify-center gap-2 md:gap-4">
              <span>{t.footer.copyright.replace('{year}', new Date().getFullYear().toString())}</span>
              <span className="hidden md:inline">•</span>
              <a 
                href={`/${currentLang}/privacy-policy`}
                className="text-[#334B40] hover:text-[#3D5A4C] hover:underline transition-colors"
              >
                {currentLang === 'de' ? 'Datenschutzerklärung' : 'Privacy Policy'}
              </a>
            </div>
          </div>
        </footer>
      </div>

      <CookieConsent
        location="bottom"
        buttonText={t.buttons.accept}
        declineButtonText={t.buttons.decline}
        cookieName="myAppConsentCookie"
        style={{ 
          background: "#334B40",
          padding: "1rem",
          alignItems: "center",
          gap: "1rem"
        }}
        buttonStyle={{ 
          background: "#FFFFFF",
          color: "#334B40",
          fontSize: "14px",
          fontWeight: "500",
          padding: "0.5rem 1.5rem",
          borderRadius: "0.375rem",
          border: "none"
        }}
        declineButtonStyle={{
          background: "transparent",
          color: "#FFFFFF",
          fontSize: "14px",
          fontWeight: "500",
          padding: "0.5rem 1.5rem",
          borderRadius: "0.375rem",
          border: "1px solid #FFFFFF"
        }}
        expires={150}
        enableDeclineButton
        flipButtons
        onAccept={handleAcceptCookies}
        onDecline={handleDeclineCookies}
        sameSite="strict"
        debug={process.env.NODE_ENV === 'development'}
        setDeclineCookie={true}
      >
        <span style={{ fontSize: "14px", color: "#FFFFFF" }}>
          {t.cookieConsent.message}
        </span>
      </CookieConsent>

      {/* Floating mobile buttons - only show when hero buttons are out of view */}
      {isMobile && !inView && (
        <motion.div 
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          exit={{ y: -100 }}
          className="fixed top-[80px] left-0 right-0 z-40 bg-white/80 backdrop-blur-sm border-b px-4 py-2 md:hidden"
        >
          <ContactButtons className="justify-center" orientation="horizontal" currentLang={currentLang} />
        </motion.div>
      )}
    </>
  );
}